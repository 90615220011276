import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';

const filters = [
    // { label: "Power Backup", filter: ".cat-1" },
    { label: "Home Appliances", filter: ".cat-2" },
    { label: "Building Circuit Protection", filter: ".cat-3" },
    { label: "Industrial Switchgear", filter: ".cat-4" },
    // { label: "Irons", filter: ".cat-5" },
    // { label: "Fuse", filter: ".cat-6" },
    // { label: "MCBs", filter: ".cat-7" }
];

const products = [
    {
        image: require('./../../images/products/home-apps/Ceiling-1.png'),
        title: 'Ceiling Fan',
        link: '/products',
        filter: 'cat-2'
    },
    // {
    //     image: require('./../../images/products/power-backup/Gel-1.png'),
    //     title: 'GEL Battery',
    //     link: '/products',
    //     filter: 'cat-1'
    // },
    {
        image: require('./../../images/products/building-circuit/Distribution-1.png'),
        title: 'Distribution Board',
        link: '/products',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/products/ind-switchgear/Changeover-1.png'),
        title: 'Change Over Switch',
        link: '/products',
        filter: 'cat-4'
    },
    
    
    // Home Apps
    {
        image: require('./../../images/products/home-apps/Ceiling-2.png'),
        title: 'Ceiling Fan',
        link: '/products',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/home-apps/Exhaust-1.png'),
        title: 'Exhaust Fan',
        link: '/products',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/home-apps/Stand-1.png'),
        title: 'Stand Fan',
        link: '/products',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/home-apps/Wall-2.png'),
        title: 'Wall Fan',
        link: '/products',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/home-apps/Wall-1.png'),
        title: 'Wall Fan',
        link: '/products',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/home-apps/Mixer-1.png'),
        title: 'Mixer Grinder',
        link: '/products',
        filter: 'cat-2'
    },

    // Power Backup
    // {
    //     image: require('./../../images/products/power-backup/Hybrid-1.png'),
    //     title: 'Hybrid Solar PVM Inverter',
    //     link: '/products',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/products/power-backup/Mounting-1.png'),
    //     title: 'Wall Mounting MPPT Solar Controller',
    //     link: '/products',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/products/power-backup/Mppt-1.png'),
    //     title: 'MPPT SPGS',
    //     link: '/products',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/products/power-backup/Panel-1.png'),
    //     title: 'Solar Panel',
    //     link: '/products',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/products/power-backup/Smf-1.png'),
    //     title: 'SMF Battery',
    //     link: '/products',
    //     filter: 'cat-1'
    // },
    // {
    //     image: require('./../../images/products/power-backup/Tubular-1.png'),
    //     title: 'Solar & Tall Tubular Battery',
    //     link: '/products',
    //     filter: 'cat-1'
    // },

    // Building Circuit Protection
    {
        image: require('./../../images/products/building-circuit/Isolator-1.png'),
        title: 'Isolator',
        link: '/products',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/products/building-circuit/Mcb-1.png'),
        title: 'Miniature Circuit Breaker',
        link: '/products',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/products/building-circuit/McbChangeover-1.png'),
        title: 'MCB Changeover Switch',
        link: '/products',
        filter: 'cat-3'
    },

    // Industrial Switchgear
    {
        image: require('./../../images/products/ind-switchgear/Fuse-1.png'),
        title: 'Fuse Unit',
        link: '/products',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/ind-switchgear/FuseSwitch-1.png'),
        title: 'Fuse Switch Unit',
        link: '/products',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/ind-switchgear/Hrc-1.png'),
        title: 'HRC Switch',
        link: '/products',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/ind-switchgear/KitKat-1.png'),
        title: 'KIT KAT',
        link: '/products',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/ind-switchgear/OffloadChangeover-1.png'),
        title: 'Offload Change Over',
        link: '/products',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/ind-switchgear/OnloadChangeover-1.png'),
        title: 'Onload Change Over',
        link: '/products',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/ind-switchgear/SwitchFuse-1.png'),
        title: 'Switch Fuse Unit',
        link: '/products',
        filter: 'cat-4'
    },

]

var bnrimg = require('./../../images/background/Products.webp');
// var bgimg1 = require('./../../images/background/cross-line.png');

class Products extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Solutions Engineered for Excellence" pagename="Our Products" description="From industrial switch gear to home essentials, EFPL Group’s products are crafted to deliver reliability, safety, and innovation. Our commitment to quality powers industries and homes alike, bringing essential solutions to life with unmatched precision." bgimage={bnrimg}/>
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            {/* Filter Nav START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation masonry-filter clearfix">
                                    <li className="active"><NavLink to={"#"} className="btn from-top" data-filter="*" data-hover="All">All</NavLink></li>
                                    {filters.map((item, index) => (
                                        <li key={index}><NavLink to={"#"} className="btn from-top" data-filter={item.filter} >{item.label}</NavLink></li>
                                    ))}
                                </ul>
                            </div>
                            {/* Filter Nav END */}
                            {/* GALLERY CONTENT START */}
                            <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                                {products.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30`}>
                                        <div className="sx-box image-hover-block">
                                            <div className="sx-thum-bx">
                                                <img src={item.image} alt="" />
                                            </div>
                                            <div className="sx-info  p-t20 text-white">
                                                <h4 className="sx-tilte"><NavLink to={item.link}>{item.title} 🡥</NavLink></h4>
                                                <p className="m-b0">{item.address}</p>
                                            </div>
                                            <a className="mfp-link" href={item.image}>
                                                <i className="fa fa-arrows-alt" />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                            {/* GALLERY CONTENT END */}
                            {/* <div className="text-center load-more-btn-outer" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                <button className="site-button-secondry btn-half"><span>Load More</span></button>
                            </div> */}
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Products;