import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './Pages/Home';

import About from './Pages/About';

import Error from './Pages/Error';

import Products from './Pages/Products';

// import ProductDetail from './Pages/ProductDetail';

import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import ScrollToTop from './Common/ScrollToTop';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <ScrollToTop />
                <div className="page-wraper">
                        <Routes>
                            <Route path='/' element={<Home/>} />

                            <Route path='/about-us' element={<About/>} />

                            {/*  */}
                            {/* <Route path='/services-detail' element={<ServiceDetail/>} /> */}
                            {/*  */}

                            <Route path='/error-404' element={<Error/>} />

                            <Route path='/products' element={<Products/>} />

                            {/* <Route path='/product-detail' element={<ProductDetail/>} /> */}


                            <Route path='/faq' element={<Faq/>} />
                            <Route path='/contact-us' element={<ContactUs/>} />
                            
                            <Route element={<Error/>} />
                        </Routes>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;