import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/pic1.jpg'),
        reviewername: 'Ravi Mehta',
        position: 'Operations Manager',
        review: '“Working with EFPL Group has transformed our supply chain. Their products are not only high-quality but also incredibly reliable. We’ve seen a substantial reduction in downtime, thanks to their robust switch gear and power backups. It’s rare to find a partner with such a commitment to excellence.”'
    },
    {
        image: require('./../../images/testimonials/pic2.jpg'),
        reviewername: 'Priya Nair',
        position: 'Product Engineer',
        review: '“EFPL’s building protection products have become an integral part of our projects. Their attention to detail and stringent quality control mean we can trust their products to deliver every time. The team at EFPL truly understands the importance of safety and durability in construction.”'
    },
    {
        image: require('./../../images/testimonials/pic3.jpg'),
        reviewername: 'Sanjay patel',
        position: 'Procurement Specialist',
        review: '“We’ve been sourcing wires and cables from EFPL for over two years, and their consistency is unmatched. Every batch meets our exacting standards, which helps us keep our projects on track. EFPL has proven to be a dependable partner for our growing needs.”'
    },
    {
        image: require('./../../images/testimonials/pic4.jpg'),
        reviewername: 'Aditi Sharma',
        position: 'Electrical Engineer',
        review: '“EFPL Group’s commitment to innovation is evident in every product. From their industrial switch gear to home appliances, we see thoughtfulness in design and cutting-edge technology. EFPL products have made our setups more efficient and seamless. Highly recommended!”'
    },
    {
        image: require('./../../images/testimonials/pic5.jpg'),
        reviewername: 'Rajesh Iyer',
        position: 'Supply Chain Director',
        review: '“With EFPL’s support, our operations have reached new heights. Their integrated supply chain and fast response times have simplified our process immensely. I’m constantly impressed by their professionalism and dedication to customer satisfaction.”'
    }
]

var bgimg1 = require('./../../images/background/bg6.jpg');
var bgimg2 = require('./../../images/background/cross-line2.png');

class Testimonials extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: false,
            margin: 30,
            nav: true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 2
                }
            }
        };
        return (
            <>
                <div className="section-full mobile-page-padding bg-repeat p-t80 p-b80" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className={`${this.props.separatoralignment} sx-separator-outer`}>
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                        <h3 className="sep-line-one">Testimonial</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL START */}
                            <OwlCarousel className="owl-carousel testimonial-home-two owl-btn-vertical-center" {...options}>
                                {testimonials.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="testimonial-1 hover-animation-1 bg-gray">
                                            <div className="testimonial-detail clearfix">
                                                <div className="testimonial-pic  scale-in-center"><img src={item.image} alt="" width={100} height={100} /></div>
                                                <span className="testimonial-position">{item.position}</span>
                                                <h4 className="testimonial-name">{item.reviewername}</h4>
                                                <span className="fa fa-quote-right" />
                                            </div>
                                            <div className="testimonial-text">
                                                <p>{item.review}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials;