import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/background/FAQs.webp');

class Faq extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Frequently Asked Questions" pagename="FAQs" description="Have questions about our products, services, or processes? Find answers here to help you understand more about EFPL Group and how we can support your needs." bgimage={bnrimg}/>
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding">
                        <div className="container">
                            <div className="faq-1">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                            <h3 className="sep-line-one">FAQs</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                <div>
                                    {/* <div className="col-md-6 faq-list"> */}
                                        {/* Accordian */}
                                        {/* <div>
                                            <h4>How do we work?</h4>
                                            <p>Progressively generate synergistic total linkage through cross media are intellectual capital. Enthusiastically parallel task team building e-tailers without standards compliant initiatives. Progressively monetize client cent outsourcing with excellent communities..</p>
                                        </div>
                                        <div>
                                            <h4>How long will it take to build my project?</h4>
                                            <p>when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popular ised sheets containing Lorem Ipsum passagese.</p>
                                        </div>
                                        <div>
                                            <h4>What services do we offer?</h4>
                                            <p>Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley.</p>
                                        </div>
                                    </div> */}
                                    <div>
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                            What types of products does EFPL Group manufacture?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a15">
                                                        <p>EFPL Group specializes in industrial switch gear, power backups, building protection products, wires & cables, and home appliances. Our products are designed to meet high standards of quality and reliability for both industrial and home applications.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                            What industries does EFPL Group serve?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>We serve a broad range of industries, including industrial manufacturing, construction, power distribution, and residential applications, providing essential products that enhance safety, efficiency, and reliability.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                           How does EFPL Group ensure the quality of its products?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Quality is a core value at EFPL Group. We operate multiple R&D facilities and employ a rigorous quality control process, ensuring that each product meets high standards for performance, safety, and durability.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                            Can EFPL Group’s products be customized for specific needs?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFour5" className="acod-body collapse">
                                                    <div className="acod-content p-a15"><p>Yes, EFPL Group offers customization options for certain products to meet the unique requirements of our clients. Contact our team to discuss customization possibilities for your project.</p></div>
                                                </div>
                                            </div>
                                            <div className="panel sx-panel">
                                                <div className="acod-head">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                            How can I get in touch with EFPL Group for further information?
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id="collapseFive5" className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                    <p>You can reach out to us via our <strong><NavLink to={"/contact-us"}>Contact Us</NavLink></strong> page, where you’ll find options to email, call, fill a form, or visit us directly. Our team is here to help with any questions or assistance you need.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Faq;