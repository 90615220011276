import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

class Footer extends React.Component {
    render() {
        let currentYear = new Date().getFullYear()

        return (
            <>
                <footer className="site-footer footer-large  footer-dark	footer-wide">

                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">

                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                            <NavLink to={"./"}>
                                                <img src={require('./../../images/logo-light.png')} alt="Inteshape" />
                                            </NavLink>
                                        </div>
                                        <p>EFPL Group manufactures high-quality switch gear, power solutions, and protection products, driven by innovation and a commitment to reliability across industries.</p>
                                        <ul className="social-icons  sx-social-links">
                                            <li><a href="https://www.facebook.com" className="fa fa-facebook" target="_blank" rel="noreferrer"> </a></li>
                                            <li><a href="https://www.instagram.com" className="fa fa-instagram" target="_blank" rel="noreferrer"> </a></li>
                                            <li><a href="https://linkedin.com" className="fa fa-linkedin" target="_blank" rel="noreferrer"> </a></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Useful Links</h5>
                                        <ul>
                                            <li><NavLink to={"/about-us"}>About</NavLink></li>
                                            <li><NavLink to={"/products"}>Products</NavLink></li>
                                            <li><NavLink to={"/faq"}>FAQs</NavLink></li>
                                            <li><NavLink to={"/contact-us"}>Contact Us</NavLink></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Our Products</h5>
                                        <ul>
                                            <li>Power Backup</li>
                                            <li>Home Appliances</li>
                                            <li>Building Circuit Protection</li>
                                            <li>Indistrial Switchgear</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* CONTACT US */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h5 className="widget-title">Contact Us</h5>
                                        <ul className="widget_address">
                                            <li>40/3, Sahibabad Rd, Mohan Nagar, Sahibabad Industrial Area Site 4, Sahibabad, Ghaziabad, Uttar Pradesh 201010</li>
                                            <li>info@efplgroup.com</li>
                                            <li>(+91) 813 0997 404</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="sx-footer-bot-left">
                                    <span className="copyrights-text">© {currentYear} <strong>EFPL Group</strong>. Designed & Developed By <strong><a className="copyrights-text" href="https://printedgeindia.com" target="_blank" rel="noreferrer">Printedge India</a></strong>.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher/>

            </>
        );
    };
};

export default Footer;