import React from 'react';

const timeline1 = [
    {
        title: 'Manufacturing of Copper & Aluminium enameled wire started',
        // description: '',
        number: '01',
        // month: 'Oct',
        year: '2001',
    },
    {
        title: 'Manufacturing of Industrial Switch Gear initiated',
        // description: '',
        number: '03',
        // month: 'Aug',
        year: '2007',
    },
    {
        title: 'Manufacturing of Building Circuit Protection started',
        // description: '',
        number: '05',
        // month: 'Oct',
        year: '2011',
    },
    {
        title: 'Started battery recycling in Kano.',
        // description: '',
        number: '07',
        // month: 'Oct',
        year: '2017',
    },
    {
        title: 'Manufacturing batteries in India',
        // description: '',
        number: '09',
        // month: 'Oct',
        year: '2021',
    },
    {
        title: 'Manufacturing plant in Ogun State, Nigeria for Power Backup',
        // description: '',
        number: '11',
        // month: 'Oct',
        year: '2023',
    },
]

const timeline2 = [
    {
        title: 'Manufacturing of copper & aluminium power cable commenced',
        // description: '',
        number: '02',
        // month: 'Mar',
        year: '2006',
    },
    {
        title: 'Opened our initial sales office in Lagos, Nigeria',
        // description: '',
        number: '04',
        // month: 'Oct',
        year: '2009',
    },
    {
        title: 'We started to manufacture the Ceiling Fan',
        // description: '',
        number: '06',
        // month: 'Oct',
        year: '2016',
    },
    {
        title: 'Opened sales offices and warehouse in Dubai',
        // description: '',
        number: '08',
        // month: 'Oct',
        year: '2020',
    },
    {
        title: 'By 2022 10 sales offices opened PAN Nigeria-Lagos, Abuja, Kano, Kaduna, Sokoto, Onitsha, Ibadan, Jos, Benin, Portharcourt.',
        // description: '',
        number: '10',
        // month: 'Oct',
        year: '2022',
    }
]

var bgimg1 = require('./../../images/background/bg-map.png');
var bgimg2 = require('./../../images/background/cross-line2.png');

class Timeline extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 sx-bg-secondry bg-no-repeat bg-bottom-center mobile-page-padding" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                    <h3 className="sep-line-one">Our Timeline</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 m-b30">
                                    {timeline1.map((item, index) => (
                                        <div className="sx-box our-story text-white" key={index}>
                                            <div className="mt-media our-story-info d-flex justify-content-start">
                                                <div className="our-story-time"><span className="date">{item.number}</span><span className="month">{item.month}</span></div>
                                                <h4 className="text-uppercase our-story-year">{item.year}</h4>
                                            </div>
                                            <div className="our-story-detail">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    {timeline2.map((item, index) => (
                                        <div className="sx-box our-story text-white" key={index}>
                                            <div className="mt-media our-story-info d-flex justify-content-start">
                                                <div className="our-story-time"><span className="date">{item.number}</span><span className="month">{item.month}</span></div>
                                                <h4 className="text-uppercase our-story-year">{item.year}</h4>
                                            </div>
                                            <div className="our-story-detail">
                                                <h4>{item.title}</h4>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Timeline;