import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import AboutBrief from '../Elements/AboutBrief';
// import WhatWeDo from './../Elements/WhatWeDo';
import WhyChooseUs from '../Elements/WhyChooseUs';
import Timeline from '../Elements/Timeline';
import Founder from './../Elements/Founder';
import Testimonials from '../Elements/Testimonials';

var bnrimg = require('./../../images/background/About.jpg');

class About extends React.Component {
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="Pioneering Quality, Powering Progress" description="At EFPL Group, we are dedicated to manufacturing excellence. With a foundation in India and a growing presence in Africa, we create reliable, high-performance solutions that empower industries, safeguard communities, and enhance daily life. Discover our journey of innovation and trust." bgimage={bnrimg}/>
                    <AboutBrief />
                    <Timeline />
                    <Founder />
                    <WhyChooseUs/>
                    <Testimonials separatoralignment="separator-center" />
                </div>

                <Footer />
            </>
        );
    };
};

export default About;