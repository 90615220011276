import React from 'react';

var bgimg1 = require('./../../images/about/GD-Agarwal.png');

class Founder extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
                        <div className="container">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-12">
                                        <div className="home-2-about bg-bottom-left bg-no-repeat bg-cover" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                    </div>
                                    </div>
                                    <div className="col-xl-7 col-lg-7 col-md-12">
                                        <div className="about-home-2 founder-mobile">
                                            <h3 className="m-t0 sx-tilte">G.D. Agarwal</h3>
                                            <h3 className="m-t0 sx-tilte" style={{fontSize:'14px', color:'#9D9E9E', marginTop:'-10px'}}>Managing Director</h3>
                                            <p>
                                                At EFPL Group, our purpose is to enhance people’s lifestyles through meaningful products. 
                                                As a leading electrical company, we - and our subsidiaries - are driven by innovation to make life better 
                                                for many people we serve. We are committed to adding value to their everyday life by providing them with 
                                                comfortable experiences.
                                            </p>
                                            <p>
                                                We offer a wide spectrum of electrical products such as fans, switch units, inverters, tubular batteries, 
                                                solar panels, and more. We believe that electrical innovation is at the forefront to elevate everyday 
                                                experiences without being immensely expensive. That’s why we manufacture high-quality appliances that are 
                                                also modestly priced for our communities. Our people-focused approach has helped us to cater to every segment 
                                                in the country.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default Founder;