import React from 'react';
// import { NavLink } from 'react-router-dom';

// var bgimg1 = require('./../../images/background/bg5.jpg');

class AboutBrief extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
                        <div className="container">
                            <div className="section-content">
                                <div className="row">
                                    {/* <div className="col-xl-5 col-lg-5 col-md-12 ">
                                        <div className="home-2-about bg-bottom-left bg-no-repeat bg-cover" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                        </div>
                                    </div> */}
                                    <div style={{margin:'20px'}}>
                                        <div className="about-home-2">
                                            <h3 className="m-t0 sx-tilte">Powering the Future and Beyond</h3>
                                            <p>Welcome to EFPL Group – a leader in high-quality manufacturing, dedicated to powering industries, homes, and communities with reliable and innovative products. With a legacy of expertise and a commitment to excellence, we are among India’s premier manufacturers with our own state-of-the-art facility in India and an upcoming plant in Africa. Our strategic growth reflects our ambition to drive sustainable, high-performance solutions in global markets.</p>
                                            <p>At EFPL Group, we specialize in designing and producing a comprehensive range of essential products that power critical infrastructure and enhance everyday life. Our product portfolio includes <strong>Industrial Switch Gear</strong>, <strong>Power Backups</strong>, <strong>Building Construction Protection products</strong>, <strong>Wires & Cables</strong>, and <strong>Home Appliances</strong> – all crafted to meet the highest standards of safety, efficiency, and durability.</p>
                                            <p>Our success is rooted in innovation, meticulous quality control, and a customer-first philosophy. Each product we manufacture reflects our commitment to excellence, ensuring performance that businesses and individuals can trust. With a strong foundation in India and expanding reach in Africa, EFPL Group is poised to make a positive impact across continents, delivering essential solutions where they’re needed most.</p>
                                            <div className="text-left">
                                            {/* <NavLink to={"/about-2"} className="site-button-link">Read More</NavLink> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
};

export default AboutBrief;