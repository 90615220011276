import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import HeroCarousel from '../Elements/HeroCarousel';
import About from './../Elements/LandingAbout';
import WhyUs from '../Elements/WhyUs';
import Legacy from './../Elements/Legacy';
// import Services1 from './../Elements/Services1';
import Products from '../Elements/ProductsCarousel';
// import ClientsLogo1 from './../Elements/ClientsLogo1';
// import Team1 from './../Elements/Team1';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <HeroCarousel />
                    <About />
                    <WhyUs />
                    <Products />
                    <Legacy />
                </div>

                <Footer />
            </>
        );
    };
};

export default Home;