import React from 'react';
// import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        title: 'Integrated Supply Chain',
        // flaticon: 'flaticon-sketch',
        description: 'We work in close collaboration with our suppliers of machinery and materials, always ensuring that the suppply meets our supreme standards of quality. A flexible and integrated supply chain has enabled us to provide cutting-edge technological solutions to meet the needs of our customers. We are constantly responding to the challenges faced by the customers and making improvements in products and materials.',
    },
    {
        count: '02',
        title: 'Driving Impactful Innovation',
        // flaticon: 'flaticon-stairs',
        description: 'We strive to bring greater happiness to our customers through best-in-class products and services. Our research helps us gain insights on how to introduce produt innovations faster and mroe effectively in an ever-evolving market. We explore different avenues in electric technology to create impactful changes that make a real difference in our lives. We also partner with several scientific communities to turn ideas in our heads into opportunities in real life.',
    },
    {
        count: '03',
        title: 'People-Centric Strategic Focus',
        // flaticon: 'flaticon-window',
        description: 'Over the last two decades in the industry, we have witnessed how technological transformation can make life more comfortable in an affordable way. Keeping this as our true north, our strategy always revolves around making people take better care of themselves by providing them products that add value to thier life. We aim to mak superior quality products more accessible to them at every stage of their life.',
    }
]

var bgimg1 = require('./../../images/background/cross-line2.png');

class WhyUs extends React.Component {
    render() {

        return (
            <>
                <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                        <h3 className="sep-line-one ">Why Choose Us?</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="sx-icon-box-wraper  icon-count-2-outer">
                                            <div className="icon-count-2 bg-white">
                                                <span className="icon-count-number">{item.count}</span>
                                                <div className="icon-xl inline-icon m-b5 scale-in-center">
                                                    <span className="icon-cell"><i className={item.flaticon} /></span>
                                                </div>
                                                <div className="icon-content">
                                                    <h4 className="sx-tilte">{item.title}</h4>
                                                    <p>{item.description}</p>
                                                    {/* <div className="text-left">
                                                        <NavLink to={"/services-detail"} className="site-button-link">Read More</NavLink>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>WHY US?</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default WhyUs;